import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useBreakpoint } from "@pheaa/channels-component-library";
import { useTheme } from "@mui/material";
import {
	PATHNAME_HOME_VIEW_AUTHENTICATED,
	PATHNAME_HOME_VIEW_UNAUTHENTICATED
} from "../../config/common";
import {
	currentClient,
	currentClientAssetRoot,
	currentClientName
} from "../../config/clients";

const Logo = props => {

	let homeURL = PATHNAME_HOME_VIEW_UNAUTHENTICATED;

	const {
		authAccessExpiresAt,
		isAuthenticated
	} = props;

	if (isAuthenticated && authAccessExpiresAt) {
		homeURL = PATHNAME_HOME_VIEW_AUTHENTICATED;
	}

	const theme = useTheme();
	const bp = useBreakpoint([
		{ key: "xs", value: theme.breakpoints.values.xs },
		{ key: "lg", value: theme.breakpoints.values.lg }
	]);
	const fileNameSm = currentClient;
	const fileNameLg = currentClient === "aes" ? currentClient : `${currentClient}-large`;
	const fileName = (bp.key === "lg" ? fileNameLg : fileNameSm);

	return (
		<Box className="cmp-header__logo" data-bp={bp.key} data-client={currentClient}>
			<Box component={Link} to={homeURL}>
				<Box
					alt={currentClientName}
					component="img"
					src={`${process.env.PUBLIC_URL}${currentClientAssetRoot}/logos/${fileName}.svg`}
				/>
			</Box>
		</Box>
	);
};

const mapStateToProps = state => {
	return {
		authAccessExpiresAt: state.auth.authAccessExpiresAt,
		isAuthenticated: state.auth.isAuthenticated
	};
};

export default connect(mapStateToProps)(Logo);